
















import { EntityType, EntityTypeOption, ProjectFormType } from '@/api/project/model'
import { basicItems, sessionItems } from '@/api/project/entity-type-option'
import { computed, defineComponent, Ref, ref, watch, inject } from '@vue/composition-api'
import { useEventBus } from '@/hooks/useEventBus'
import { entityTypeTemplate, handleNlpSettings } from '@/api/project/entity-type-template'
import { UPDATE_BASIC_MESSAGE_ENTITY, UPDATE_SESSION_MESSAGE_ENTITY } from '@/config/event-bus'
import _, { cloneDeep } from 'lodash'

export default defineComponent({
    props: {
        value: String,
        istestGruop: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { busEmit } = useEventBus()
        const formType: ProjectFormType | undefined = inject('formType')
        const itemObj: any = inject('itemObj')
        const conversationObj: any = inject('conversationObj')
        const optionItems: Ref<EntityTypeOption[]> = ref([])
        const optionValue = ref(itemObj.message_entity.entity_type)
        if (formType == ProjectFormType.Basic) {
            optionItems.value = [...basicItems]
        } else if (formType == ProjectFormType.Session) {
            if((props.value=="image"||props.value=="video")&&props.istestGruop){
                optionItems.value=sessionItems.filter(item=>item.value=="image"||item.value=="video")
            }else{
                optionItems.value = [...sessionItems]
            }
        }


        const handlePoll = () => {
            if (formType == ProjectFormType.Basic) {

                if (
                    [EntityType.onboarding_poll, EntityType.onboarding_poll_multi, EntityType.onboarding_poll_picture, EntityType.onboarding_poll_picture_multi].includes(
                        itemObj.message_entity.entity_type
                    )
                ) {
                    optionValue.value = EntityType.onboarding_poll
                }
            } else if (formType == ProjectFormType.Session) {

                if ([EntityType.poll_multi, EntityType.picture_poll, EntityType.picture_poll_multi].includes(itemObj.message_entity.entity_type)) {
                    optionValue.value = EntityType.poll
                }
                if ([EntityType.upload_image, EntityType.upload_video, EntityType.upload_mix].includes(itemObj.message_entity.entity_type)) {
                    optionValue.value = EntityType.upload_image
                }
            }
        }
        handlePoll()
        watch(
            () => itemObj.message_entity.entity_type,
            () => handlePoll()
        )

        if (itemObj.message_entity.entity_type != EntityType.branch) {
            optionItems.value = optionItems.value.filter((v) => EntityType.branch != v.value)
        }


        const optionIcon = computed(() => {
            return optionItems.value.find((v) => v.value == optionValue.value)?.icon || ''
        })

        const handleEntityType = (val: EntityType) => {
            const typeOption = optionItems.value.find((v) => v.value == val)
            const entityTypeTemplateCopy = _.cloneDeep(entityTypeTemplate)
            Object.keys(entityTypeTemplateCopy).forEach((key: any) => {
                if (key == 'settings') {
                    if (itemObj.message_entity[key].length > 0) {
                        itemObj.message_entity[key][0].options = {
                            none: false,
                            other: false,
                            randomized: false
                        }
                        itemObj.message_entity[key][0].ai_enable = true
                        itemObj.message_entity[key][0].recommend_enable = true
                    } else {
                        itemObj.message_entity[key].push({
                            ai_enable: true,
                            options: {
                                randomized: false,
                                other: false,
                                none: false
                            },
                            recommend_enable: true,
                        })
                    }
                } else if (key == 'content') {

                } else {
                    itemObj.message_entity[key] = entityTypeTemplateCopy[key]
                }
            })
            itemObj.message_entity.entity_type = typeOption?.value
            itemObj.message_entity.duration = typeOption?.duration
            if (formType == ProjectFormType.Basic) {

                busEmit(UPDATE_BASIC_MESSAGE_ENTITY, itemObj)
            } else if (formType == ProjectFormType.Session) {

                const { nlp_settings } = cloneDeep(handleNlpSettings(conversationObj.sections))
                itemObj.message_entity.nlp_settings = nlp_settings
                busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
            }
        }
        return {
            EntityType,
            optionItems,
            optionIcon,
            handleEntityType,
            itemObj,
            optionValue,
            conversationObj
        }
    }
})
