import { render, staticRenderFns } from "./BasicPoll.vue?vue&type=template&id=251a2958&scoped=true&"
import script from "./BasicPoll.vue?vue&type=script&lang=ts&"
export * from "./BasicPoll.vue?vue&type=script&lang=ts&"
import style0 from "./BasicPoll.vue?vue&type=style&index=0&id=251a2958&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251a2958",
  null
  
)

export default component.exports