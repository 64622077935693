import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=6613f894&scoped=true&"
import script from "./Image.vue?vue&type=script&lang=ts&"
export * from "./Image.vue?vue&type=script&lang=ts&"
import style0 from "./Image.vue?vue&type=style&index=0&id=6613f894&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6613f894",
  null
  
)

export default component.exports