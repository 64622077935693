













import { defineComponent, inject, watch } from '@vue/composition-api'
import TypeSelect from './TypeSelect.vue'
import Editor from '@/components/Editor.vue'
import { EntityType, ProjectFormType } from '@/api/project/model'
import { useEventBus } from '@/hooks/useEventBus'
import { UPDATE_SESSION_MESSAGE_ENTITY } from '@/config/event-bus'
export default defineComponent({
    components: {
        TypeSelect,
        Editor
    },
    setup(props, { emit }) {
        const { busEmit } = useEventBus()
        const itemObj: any = inject('itemObj')
        const onChange = () => {
            busEmit(UPDATE_SESSION_MESSAGE_ENTITY, itemObj)
        }

        watch(
            () => itemObj.message_entity.content,
            () => onChange()
        )
        return {
            EntityType,
            ProjectFormType,
            itemObj
        }
    }
})
